










































































































































import Component, { mixins } from 'vue-class-component'
import { State } from '@/mixins/state'

import {
  SubscriptionApi,
  EditSubscriptionCommand,
  ArticleDto2
} from '@/api'

import Content from '@/components/Content.vue'
import Toolbar from '@/components/Layout/Toolbar.vue'
import FormInput, { InputField } from '@/components/Form/Input.vue'
import FormButton from '@/components/Form/Button.vue'
import { User } from '@/types'
import AlertError from '@/components/Global/Alert/AlertError.vue'
import Loader from '@/components/Global/Loader.vue'

interface InputFields {
  explanation: InputField;
}

@Component({
  components: {
    Content,
    Toolbar,
    FormInput,
    FormButton,
    AlertError,
    Loader
  }
})
export default class SubscriptionEdit extends mixins(State) {
  possibleSportLocationArticles: ArticleDto2[] = []
  initialSportLocationArticle = ''
  selectedSportLocationArticle = ''
  selectedSportArticleDescription = ''
  noArticlesFound = false

  get isFiscFreeSubscription () {
    return this.currentUser.subscriptionDetails?.referrer === 'FiscFree'
  }

  public gotoMyFitnessPlan () {
    if (this.currentUser.projectInformation?.registrationFlowLoginUri) {
      const win = window.open(this.currentUser.projectInformation?.registrationFlowLoginUri, '_blank')
      if (win) {
        win.focus()
      }
    }
  }

  public gotoFiscFreePlan () {
    const win = window.open('https://applicatie.fiscfree.nl/werknemer/Login.jsp', '_blank')
    if (win) {
      win.focus()
    }
  }

  // subscriptionDetails.renewalNumber === 0 dan mag je niet wijzigen
  //  Je mag nog niet van abonnement wisselen omdat je nog in je eerste looptijd zit

  inputFields: InputFields = {
    explanation: {
      name: 'explanation',
      value: '',
      required: false,
      type: 'textarea',
      placeholder: 'Eventuele toelichting',
      errors: [],
      inline: false
    }
  }

  mounted () {
    this.getSportLocationArticles()
  }

  public async getSportLocationArticles () {
    this.setState(this.states.LOADING)
    const api = new SubscriptionApi()
    const articles = await api.subscriptionGetSportLocationArticles(
      this.currentUser.subscription?.subscriptionId || 0
    )
    this.setState(this.states.INITIAL)
    this.possibleSportLocationArticles = articles.data
    this.noArticlesFound = this.possibleSportLocationArticles.length === 0

    this.selectedSportArticleDescription = this.selectedSportLocationArticle = this.initialSportLocationArticle = this.currentUser.subscriptionDetails?.articles?.find(article => {
      return this.possibleSportLocationArticles.find(availableArticle => availableArticle.code === article.code)
    })?.description || ''

    this.selectedSportLocationArticle = this.initialSportLocationArticle = this.currentUser.subscriptionDetails?.articles?.find(article => {
      return this.possibleSportLocationArticles.find(availableArticle => availableArticle.code === article.code)
    })?.code || ''
  }

  public async editSubscription () {
    this.clearErrors()
    if (!this.validateInput()) {
      return
    }

    const api = new SubscriptionApi()
    const fields: EditSubscriptionCommand = {
      subscriptionId: this.currentUser.subscription?.subscriptionId || 0,
      articleCode: this.selectedSportLocationArticle,
      explanation: this.inputFields.explanation.value
    }
    try {
      this.state = this.states.LOADING
      this.clearErrors()
      await api.subscriptionEditSubscription(
        fields
      )
      await api.subscriptionEditSubscriptionEmployee(
        fields
      )
      this.state = this.states.COMPLETE
    } catch (error) {
      this.state = this.states.ERROR

      this.setErrors(error.response.data.errors)
    }
  }

  public isActiveArticle (code: string) {
    return this.currentUser.subscriptionDetails?.articles?.find(article => article.code === code)
  }

  public subscriptionExpires () {
    return this.currentUser.subscriptionDetails?.subscriptionStatus === 'Expires'
  }

  public isEditAllowed () {
    // Renewals can always be edited.
    if (this.currentUser.subscriptionDetails?.renewalNumber !== 0 || this.subscriptionExpires()) {
      return true
    }

    // Edit during inital period is only allowed if there are (pricier) articles to choose from.
    // This is already filtered in the API.
    let count = this.possibleSportLocationArticles?.length || 0
    this.possibleSportLocationArticles.forEach(article => {
      const found = this.currentUser.subscriptionDetails?.articles?.find(a => a.code === article.code)
      if (found) {
        count--
      }
    })

    return count > 0
  }

  private validateInput () {
    let valid = true

    valid = (this.$refs.explanation as FormInput).validate() && valid

    return valid
  }

  public setErrors (errors: []) {
    Object.entries(errors).forEach(([errorKey, errorValue]) => {
      Object.entries(this.inputFields).forEach(([fieldKey, fieldValue]) => {
        if (errorKey.toLowerCase() === fieldKey.toLowerCase()) {
          fieldValue.errors = errorValue
        }
      })
    })
  }

  public clearErrors () {
    Object.values(this.inputFields).forEach((fieldValue: InputField) => {
      fieldValue.errors = []
    })
  }

  get currentUser (): User {
    return this.$store.state.currentUser
  }

  get subscription () {
    return this.currentUser.subscriptionDetails
  }
}
